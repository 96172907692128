import React from 'react';
import './styles.scss';

class Review extends React.Component {
    render() {
        return (
            <a
                href='https://www.g2.com/products/trivia/reviews/'
                target='_blank'
                rel='noreferrer noopener'
                className='reviewWrap'
            >
                <div className='reviewBadge'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/misc/leader.png?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/leader.png'}
                        alt={'leader'}
                    />
                </div>
                <div className='reviewLeft'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/misc/leader-bg.svg?blur=30'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/leader-bg.svg'}
                        alt='Springworks Review'
                    />
                    <img
                        className='union-desk'
                        src={'https://sw-assets.gumlet.io/trivia/misc/union.svg'}
                        data-src={'https://sw-assets.gumlet.io/trivia/misc/union.svg'}
                        alt='Springworks Review'
                    />
                    <div className='union-mob'>
                        <img
                            className='union-desk'
                            src={'https://sw-assets.gumlet.io/trivia/misc/union-mob.svg'}
                            data-src={'https://sw-assets.gumlet.io/trivia/misc/union-mob.svg'}
                            alt='Springworks Review'
                        />
                    </div>
                </div>
                <div className='reviewRight'>
                    <img
                        src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo-black.svg'}
                        data-src={'https://sw-assets.gumlet.io/trivia/logos/trivia-logo-black.svg'}
                        alt={'Springworks Review'}
                    />
                    <h3>
                        Trivia has been named a <span>Leader</span> in its category in the{' '}
                        <span className={'orange'}>
                            G2 Grid<sup>&reg;</sup>
                        </span>{' '}
                        report for <span>Winter 2021.</span>
                    </h3>
                </div>
            </a>
        );
    }
}

export default Review;
