import React from 'react';
import './styles.scss';
import Carousel from 'react-bootstrap/Carousel';

const CaseStudies = () => {
    return (
        <div className={'case-studies-container'}>
            <div className='caseStudiesWrap'>
                <div className='caseStudiesHeader'>
                    <h4>Case studies</h4>
                </div>
                <div className='caseStudiesParent'>
                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/vedantu-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/vedantu-logo.png'}
                            alt={'vedantu'}
                        />
                        <h6>
                            An Indian Interactive Online tutoring platform added 52,000 minutes of Remote Employee
                            Engagement with Trivia.
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/trivia-at-vedantu-a-case-study/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>

                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/science-small-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/science-small-logo.png'}
                            alt={'science'}
                        />
                        <h6>
                            How Trivia helped a venture studio worth more than $1.3B to bond better, together as a
                            remote team?
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/science-inc-case-study/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>

                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/skillenza-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/skillenza-logo.png'}
                            alt={'skillenza'}
                        />
                        <h6>
                            How a data-driven skill acceleration platform reinvented virtual icebreakers with Trivia?
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/trivia-at-skillenza/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>
                </div>
            </div>
            <Carousel className='customerWrapCS'>
                <Carousel.Item>
                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/vedantu-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/vedantu-logo.png'}
                            alt={'vedantu'}
                        />
                        <h6>
                            An Indian Interactive Online tutoring platform added 52,000 minutes of Remote Employee
                            Engagement with Trivia.
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/trivia-at-vedantu-a-case-study/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/science-small-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/science-small-logo.png'}
                            alt={'science'}
                        />
                        <h6>
                            How Trivia helped a venture studio worth more than $1.3B to bond better, together as a
                            remote team?
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/trivia-at-skillenza/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='caseStudies'>
                        <img
                            src={'https://sw-assets.gumlet.io/trivia/customers/skillenza-logo.png?blur=30'}
                            data-src={'https://sw-assets.gumlet.io/trivia/customers/skillenza-logo.png'}
                            alt={'skillenza'}
                        />
                        <h6>
                            How a data-driven skill acceleration platform reinvented virtual icebreakers with Trivia?
                        </h6>
                        <a
                            href='https://www.springworks.in/blog/trivia-at-vedantu-a-case-study/'
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            Read....
                        </a>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
};

export default CaseStudies;
