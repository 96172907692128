import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../../../components/trivia/NavbarOld';
import Footer from '../../../../components/trivia/Footer';
import Testimonials from '../../../../components/trivia/TestimonialsOld';
import Review from '../../../../components/trivia/Review';
import WorkSpace from '../../../../components/trivia/WorkSpace';
import CaseStudies from '../../../../components/trivia/CaseStudies';
import '../../slack/styles.scss';
import './styles.scss';
import { getUtmParams } from '../../../../util/getUtmFromUrl';

const slackAddUrl = process.env.GATSBY_TRIVIA_SLACK_ADD_URL;
const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

class CustomQuiz extends React.Component {
    state = { show: false, utm: '' };

    componentDidMount() {
        const utm = getUtmParams();
        this.setState({ show: true, utm });
    }

    handleShow = () => {
        if (window?.dataLayer) window.dataLayer.push({ event: 'triviaTeamsInstallEvent' });
        this.setState({ show: true });
    };

    render() {
        const { utm } = this.state;
        return (
            <>
                <Helmet>
                    <title>Custom Quizzes | Trivia</title>
                    <meta
                        name='description'
                        content='Create & play quizzes with your very own questions right inside Slack & Microsoft Teams. Take engagement up a notch with customisable quizzes inside Trivia.'
                    />
                    <meta property='og:title' content='Custom Quizzes: Create Quizzes in Slack & MS Teams | Trivia' />
                    <meta property='og:site_name' content='TRIVIA' />
                    <meta property='og:url' content='https://www.springworks.in/trivia/games/custom-quiz/' />
                    <meta
                        property='og:description'
                        content='Create & play quizzes with your very own questions right inside Slack & Microsoft Teams. Take engagement up a notch with customisable quizzes inside Trivia.'
                    />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://trivia-custom-gifs.s3.amazonaws.com/trivia-landing-page-videos/twitter-url.png'
                    />
                    <meta name='twitter:card' content='summary' />
                    <meta name='twitter:site' content='@springroleinc' />
                    <meta name='twitter:title' content='Custom Quizzes: Create Quizzes in Slack & MS Teams | Trivia' />
                    <meta
                        name='twitter:description'
                        content='Create & play quizzes with your very own questions right inside Slack & Microsoft Teams. Take engagement up a notch with customisable quizzes inside Trivia.'
                    />
                    <link rel='canonical' href='https://www.springworks.in/trivia/games/custom-quiz/' />
                </Helmet>
                <div className='pageWrap'>
                    <div className='gamesWrap'>
                        <Navbar />
                        <div className='slackHeader'>
                            <h1>Personalize your quizzes to perfection with Trivia</h1>
                            <p>
                                Craft customized questions and answers, launch them with Trivia and nurture happy teams
                                - right where your conversations happen.
                            </p>
                            <div className='addBtn'>
                                <button
                                    id='TRIVIA-SLACK-INSTALL'
                                    onClick={() => window.open(encodeURI(`${slackAddUrl}${utm}`), '_blank')}
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/slack.svg'}
                                        alt={'slack'}
                                    />
                                    Add Trivia to Slack
                                </button>
                                <button
                                    id='TRIVIA-TEAMS-INSTALL'
                                    onClick={() => window.open(encodeURI(`${teamsAddUrl}${utm}`), '_blank')}
                                >
                                    <img
                                        src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                        alt={'microsoft teams'}
                                    />
                                    Add Trivia to Teams
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='rt-container'>
                        <div className='stepsCustomQuizWrap'>
                            <div className='stepsCustomQuiz'>
                                <h4>Three simple steps to create custom quizzes with Trivia</h4>
                            </div>

                            <div className='cqStepsWrap'>
                                <div className='cqStepsLeft'>
                                    <div className='cqStepsSL'>
                                        <span>01</span>
                                    </div>
                                    <div className='cqSteps'>
                                        <h3>Install Trivia on your Slack/MS Teams</h3>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/games/custom-quiz/start.svg?blur=30'
                                            }
                                            data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/start.svg'}
                                            alt={'install'}
                                        />
                                        <p>
                                            Add Trivia to preferred channels. Set it up for your team to create and play
                                            custom quizzes.
                                        </p>
                                    </div>
                                </div>
                                <div className='cqStepsRight cqStepsSP'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/start.svg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/start.svg'}
                                        alt={'install'}
                                    />
                                </div>
                            </div>
                            <div className='cqStepsWrap'>
                                <div className='cqStepsRight'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/input.svg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/input.svg'}
                                        alt={'create'}
                                    />
                                </div>
                                <div className='cqStepsLeft cqStepsSP'>
                                    <div className='cqStepsSL'>
                                        <span>02</span>
                                    </div>
                                    <div className='cqSteps'>
                                        <h3>Create a Custom Quiz with your questions</h3>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/games/custom-quiz/input.svg?blur=30'
                                            }
                                            data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/input.svg'}
                                            alt={'create'}
                                        />
                                        <p>
                                            Open your personal chat with Trivia and submit 'custom'. Then simply enter
                                            the questions, MCQ choices and the correct answer.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='cqStepsWrap'>
                                <div className='cqStepsLeft'>
                                    <div className='cqStepsSL'>
                                        <span>03</span>
                                    </div>
                                    <div className='cqSteps'>
                                        <h3>One-click launch</h3>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/games/custom-quiz/launch.svg?blur=30'
                                            }
                                            data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/launch.svg'}
                                            alt={'launch'}
                                        />
                                        <p>
                                            Launch your custom quiz on preferred channels, engage your teams and spread
                                            smiles.
                                        </p>
                                    </div>
                                </div>
                                <div className='cqStepsRight cqStepsSP'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/launch.svg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/launch.svg'}
                                        alt={'launch'}
                                    />
                                </div>
                            </div>

                            <div className='cqStepsWrap'>
                                <div className='cqStepsRight'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/team.svg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/team.svg'}
                                        alt={'bond'}
                                    />
                                </div>
                                <div className='cqStepsLeft cqStepsSP'>
                                    <div className='cqSteps'>
                                        <h3>Team bonding made super simple</h3>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/games/custom-quiz/team.svg?blur=30'
                                            }
                                            data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/team.svg'}
                                            alt={'bond'}
                                        />
                                        <p>
                                            Be it a personalized quiz about your teammate on their birthdays or
                                            icebreaker questions to onboard new employees, Trivia’s custom quizzes help
                                            you bond with your teams in ways you’d never imagined.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='cqStepsWrap'>
                                <div className='cqStepsLeft'>
                                    <div className='cqSteps'>
                                        <h3>Play precisely when you prefer</h3>
                                        <img
                                            src={
                                                'https://sw-assets.gumlet.io/trivia/games/custom-quiz/play.svg?blur=30'
                                            }
                                            data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/play.svg'}
                                            alt={'choose timing'}
                                        />
                                        <p>
                                            Be it Monday mornings or Thursday afternoons, Trivia helps you launch
                                            quizzes whenever you want with your remote teams.
                                        </p>
                                    </div>
                                </div>
                                <div className='cqStepsRight cqStepsSP'>
                                    <img
                                        src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/play.svg?blur=30'}
                                        data-src={'https://sw-assets.gumlet.io/trivia/games/custom-quiz/play.svg'}
                                        alt={'choose timing'}
                                    />
                                </div>
                            </div>
                        </div>

                        <Testimonials />
                        <Review />
                    </div>
                    <CaseStudies />
                    <WorkSpace />
                </div>
                <Footer />
            </>
        );
    }
}

export default CustomQuiz;
